import React from 'react';
import { Form, Row, Col, Checkbox, Input, Select, message, Divider } from 'antd';
import {LangConsumer} from 'utils/LangProvider';
import {Sortable} from '../../../../component';
import Ability from '../../../../ability';
import WebApp from '../../../../webapp';
import {} from '../../../../common';
import _ from 'lodash';
import './index.less';
import {osdItems, osdItemCaps, osdTrans, snapTrans, publicOSD, ruleKeyMap, ruleTransMap} from './stringMap';

class SortableItems extends React.Component {
    constructor (props) {
        super(props);
    }
    /**
     * 获取可拖拽的属性
     */
    getSortableItems = () => {
        let {$t, orderItems, osdType, order} = this.props;
        let sortableItems = [];
        console.log('getSortableItems', orderItems);
        // 对orderItems进行数组去重
        let arr = [], orderArr;
        // 分割排序字符串
        if (order) {
            orderArr = order.replace(/(%[0-9]+)(?=%|)/ig,'&$1&').split(/[&]+/g);
        }
        console.log(orderArr);
        orderItems.forEach(item => {
            if (arr.indexOf(item.Name) === -1) { // 去掉重复项
                arr.push(item.Name);
                if (order.indexOf(item.Name) !== -1) { // 这里是为了对地点进行特殊处理
                    if (item.Name === '%y%M%d%h%m%s' || item.Name === '%19') {
                        sortableItems.push({value: item.Name, text: $t(osdTrans[publicOSD[item.Name]]), num: orderArr.indexOf(item.Name)});
                    } else {
                        sortableItems.push({value: item.Name, text: $t(osdTrans[osdItems[osdType][item.Name]]), num: orderArr.indexOf(item.Name)});
                    }
                }
            }
        });
        // 根据order进行排序
        sortableItems.sort((a, b) => {
            return a.num - b.num;
        });
        return sortableItems;
    }
    /**
     * 当拖拽结束后触发事件
     * @param {object} e 事件对象
     */
    handleExchange = (e) => {
        let {$t, orderItems, osdType, configJson, currentChannel} = this.props;
        let newOrder = '',
            texts = []; // 生成一个排序后的数组
        for (let i in e.item.parentNode.children) {
            if (e.item.parentNode.children[i] && e.item.parentNode.children[i].innerText) {
                texts.push(e.item.parentNode.children[i].innerText);
            }
        }
        texts.forEach(name => {
            orderItems.forEach(item => {
                if ($t(osdTrans[publicOSD[item.Name]]) === name || $t(osdTrans[osdItems[osdType][item.Name]]) === name) { // 判断是否已经勾选
                    newOrder += item.Name;
                }
            });
        });
        configJson[currentChannel][osdType].OSDOrder = newOrder;
        this.props.updateConfigJson(configJson);
    }
    render () {
        let sortableItems = this.getSortableItems();
        return (
            <ul className='_SortableItems_'>
                <Sortable onSort = {(e) => this.handleExchange(e, 'DI')}>
                    {sortableItems.map(item => {
                        return <li className='traffic_OSD_move' key={item.text} title={item.text}>{item.text}</li>;
                    })}
                </Sortable>
            </ul>
        );
    }
}

export default LangConsumer(SortableItems);